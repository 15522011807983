.report-tab-menu.ui.tabular.menu .active.item {
    border-radius: 1rem !important;
    background-color: #3E66CD !important;
    color: #ffffff;
}

.report-tab-menu-item {
    border-radius: 1rem !important;
    background-color: #ffffff !important;

    display: block !important;
    text-align: center !important;
}

.report-tab-pane-container div .ui.attached.tabular.menu {
    border-bottom: none !important;
}

.report-tab-pane {
    border: 0 !important;
    background: none !important;
    border-radius: 1.5rem !important;
}

/*-- Staff report styles --*/
.report-pane-staff {
    background: #ffffff;
    border-radius: 1.5rem !important;
}

.report-staff-chart-title {
    font-size: 18px;
    font-weight: 600;
}

.report-staff-chart-sub {
    font-size: 10px;
    font-weight: 500;
}

.report-pane-chart {
    background: #F8F8F8;
    border-radius: 1.5rem;
}

.report-staff-drop {
    background: #ececec !important;
    border: none !important;
    border-radius: .8rem !important;
}

.report-pane-table .table-responsive {
    /* height: 400px; */
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: 10px;
}

.report-pane-table .table thead td {
    position: sticky;
    top: 0;
    font-size: 12px;
    font-weight: 500;
    background-color: #fff;
}

.report-pane-table .table tbody td {
    font-size: 11px;
    font-weight: 400;
}


.report-pane-chart .table-responsive::-webkit-scrollbar {
    display: none;
}

.report-staff-card {
    border-radius: 2rem;
    transition: all .1s;

}

.report-staff-card.selected {
    background: #102D77;
    color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
}

.report-staff-card.default {
    background: #ffffff;
    color: #000000;
}

.report-staff-card-head-title {
    font-size: 14px;
    font-weight: 600;
}

.report-staff-card-title {
    font-size: 13px;
    font-weight: 600;
}

.report-staff-card-position {
    font-size: 10px;
    font-weight: 400;
}

.report-staff-card-phone {
    font-size: 10px;
    font-weight: 500;
}

.report-staff-card-img img {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    object-fit: cover;
    background: #dfdfdf;
}

.custom-checkbox-1 label:before,
.custom-checkbox-1 label {
    border-color: #000000 !important;
    /* color: red !important; */
    font-size:11px!important;
}

/* .custom-checkbox-1 label:before,
.custom-checkbox-1 label {
    border-color: red !important;
    color: red !important;
} */

.custom-checkbox-2 label:before,
.custom-checkbox-2 label {
    border-color: #25B4D3 !important;
    color: #25B4D3 !important;
    font-size:11px!important;
}

.custom-checkbox-3 label:before,
.custom-checkbox-3 label {
    border-color: #35D40D !important;
    color: #35D40D !important;
    font-size:11px!important;
}

.custom-checkbox-4 label:before,
.custom-checkbox-4 label {
    border-color: #D31C1C !important;
    color: #D31C1C !important;
    font-size:11px!important;
}

.custom-checkbox-5 label:before,
.custom-checkbox-5 label {
    border-color: #DEC910 !important;
    color: #DEC910 !important;
    font-size:11px!important;
}
.custom-checkbox-6 label:before,
.custom-checkbox-6 label {
    border-color: #9745FF !important;
    color: #9745FF !important;
    font-size:11px!important;
}

.status-container .Closed{
    color: #32f302;
    
}
.status-container .Open{
    color: #25b4d3;
}
.status-container .Rejected{
    color: #d31c1c;
}
.status-container .Unavailable{
    color: #dec910;
}
.status-container .Paused{
    color: #5437f7c7;
}

.report-staff-img {
    width: 5rem;
    height: 6rem;
    border-radius: 1rem;
    object-fit: cover;
    background: #dfdfdf;
}

.scrollable-agent-list{
    overflow-y: auto;
    height: 11em;
    overflow-x: hidden;
}