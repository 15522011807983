.progress-chart {
    border-radius: 2rem;
    background-color: #ffffff;
    transition: all .1s;
}

.progress-chart-container{
    height: 30.75rem;
}

.progress-card-title{
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.progress-content{
    max-height: 25rem;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.progress-content::-webkit-scrollbar {
    display: none;
}

.progress-bar-subtitle{
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.44rem 0.56rem 0.44rem 0.56rem;
    border-radius: 0.725rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #CB9CF0;
}

.progress-parent-div {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    height: 2.0015em;
    width: 100%;
    background-color: #ece9e9;
    border-radius: 0.50038rem;
    margin: 1rem;
    position: relative;
}

.progress-parent-div>.text {
    z-index: 2;
    height: 1.0rem;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
}

.progress-child-div {
    padding: 0 0 0 0;
    margin: 0;
    position: relative;
    height: 100%;
    border-radius: 0.50038rem;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}

.progress-title {
    font-size: 1.00075rem;
    font-weight: 500;
}

.chart-card.progress-chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    height: 100%;
}

.progress-cont {
    margin-bottom: 10px;
}

.progress-parent-div.progress-chart {
    height: 1.5em;
    position: relative;
}
