.right-box {
  background-color: #001c50;/*#427fef*/
  height: 100vh;
}
/* 
@media screen and (min-width:6) {
  .right-box {
    height: 100v;
  }
} */

.phone-input::placeholder {
  color: white;
}

.input-page1 {
  outline: none;
  border: 1px solid white;
  height: 35px;
  border-radius: 5px;
  color: white;
}

.input-page2 {
  outline: none;
  border: 1px solid white;
  border-radius: 5px;
}

.input-checkbox {
  outline: none;
  border: 2px solid white;
  width: 20px;
  height: 20px;
}

.button-page1 {
  outline: none;
  border: none;
  height: 35px;
  border-radius: 5px;
  color: #427fef;
  font-weight: bold;
}

.login-span {
  color: #f9c643;
}

.register-span {
  color: #f9c643;
}

.otp-input input {
  /* background: transparent; */
  border: none;
  outline: none;
  background-color: #6697f2;
  border-radius: 10px;
  min-width: 50px;
  min-height: 50px;
}

.bottom-round1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #427fef;
}

.bottom-round2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid gray;
}

.text-error {
  color: rgb(224, 7, 7);
  font-size: 0.8em;
}

.right-box .logo{
  display: none;
}

.left-main{
  display: block;
}

@media screen and (min-width: 768px) {
  .form-basic{
    margin-bottom: 270px;
  }
}

@media screen and (max-width: 768px) {
  
  .carousel-indicators {
    display: none;
  }

  .left-main{
    display: none !important;
  }
  .right-box .logo{
    display: block;
    margin-top:-170px;
  }

}

/* .carousel-text{
  height: 7rem !important;
  min-height: 7rem !important;
  max-height: 7rem !important;
  margin-top: 1rem !important;
} */

.carousel-image{
  width: 50%;
}

@media screen and (max-width: 360px) {
  .carousel-text {
    font-size: 4vw;
  }
}

@media screen and (min-width: 768px) {
  .carousel-indicators {
    position: absolute;
    top: 110%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
  }

  .carousel-image{
    width: 50%;
  }
  
}

.carousel-indicators button {
  transform: scale(0.4);
  min-height: 30px;
  min-width: 15px;
  border-radius: 50%;
  border: 1px solid black;
}

.carousel-indicators [data-bs-target] {
  margin-right: 1px; /* Adjust margin-right to reduce spacing */
  margin-left: 1px; /* Adjust margin-left to reduce spacing */
}

.carousel-indicators button:not(.active) {
  /* Apply border to inactive buttons */
  border: 1px solid #333;
}

.carousel-indicators .active {
  background-color: #427fef;
  border: 1px solid #427fef;
}

.top-wrapper {
  border: 1px solid #427fef;
  opacity: 1;
  position: relative;
}

.top-right {
  opacity: 1;
  overflow-x: scroll; /* Hide horizontal scrollbar */
  white-space: nowrap;
  height: inherit;
  position: relative;
  scrollbar-width: none;
  margin-bottom: -17px;
}

.top-right::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.comment {
  height: 100%;
  color: #6697f2;
}

.top-right:before {
  content: "";
  position: absolute;
  top: 0;
  left: 7px;
  right: -10px;
  height: 100%;
  width: 1px;
  background-color: #427fef;
  transform: skewX(20deg);
  z-index: -1;
}

.top-left {
  height: inherit;
  width: 50px;
}

