
.adduser-img img{
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    object-fit: cover;
    background: #dfdfdf;
    /* overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #F2F2F2; */
}

.adduser-btn-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.adduser-btn{
    border-radius: 2rem;
}

.pass-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;

}

.password1{
    width: 100%;
    background-color: lightgrey !important;
    border:1px solid;
    outline: none;
}
/* .password1.first{
    padding-right: 10px
} */

.passC{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px
}

.passC.body{
    gap:1px;
}

.passC.btn{
    border-radius: 1rem;
    width: auto; 
}

.eye.btn{
    border:none;
    outline: none;
    display: flex;
    width: auto;
    padding-top: 10px;
    margin: 0px;
    right: -2.4rem;
    align-items: center;
    justify-content: center;
}