.App {
  /* text-align: center; */
  /* background: #F8F8F8; */
  height: 100%;
}
body{
  font-family: 'Poppins';
  background-color: #F6F6F6;
}

:root {
  --staffTheme:#011021;
  --staffCard:#1B5293;
  --staffTable:#1B5293;
  --companyTheme:#130857;
  --companyCard:#FFF;
  --companyTable:#1e4eca;
}

.btn-primary.agent {
  --bs-btn-bg: #1B5293;
  --bs-btn-border-color: #1B5293;
  --bs-btn-hover-bg: #0d3c75;
  --bs-btn-hover-border-color: #0d3c75;
  --bs-btn-active-bg: #0d3c75;
  --bs-btn-active-border-color: #082f6a;
  --bs-btn-disabled-bg: #1B5293;
  --bs-btn-disabled-border-color: #1B5293;
}

.text-bg-primary.agent {
  color: #fff !important;
  background-color: #1B5293 !important;
}

/* Common css */

.btn-pill{
  border-radius: 2rem;
}

.mouse-pointer{
  cursor: pointer;
}

.form-control{
  font-size: 11px;
}

.col.col-1{
  flex: 0 0 auto !important;
  width: 6.25% !important;
}

.col-1.col-2{
  flex: 0 0 auto !important;
  width: 12.5% !important;
}

.col-2.col-3{
  flex: 0 0 auto !important;
  width: 20.8333333334% !important;
}

.col-3.col-4{
  flex: 0 0 auto !important;
  width: 29.16666666667% !important;
}

.col-4.col-5{
  flex: 0 0 auto !important;
  width: 37.5% !important;
}

.col-5.col-6{
  flex: 0 0 auto !important;
  width: 45.833333333334% !important;
}

.col-6.col-7{
  flex: 0 0 auto !important;
  width: 54.166666666667% !important;
}

.col-7.col-8{
  flex: 0 0 auto !important;
  width: 62.5% !important;
}

.col-8.col-9{
  flex: 0 0 auto !important;
  width: 70.833333333334% !important;
}

.col-9.col-10{
  flex: 0 0 auto !important;
  width: 79.1666666666667% !important;
}

.col-10.col-11{
  flex: 0 0 auto !important;
  width: 87.5% !important;
}

.col-11.col-12{
  flex: 0 0 auto !important;
  width: 95.8333333333334% !important;
}