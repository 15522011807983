.crop-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px;
}

.crop-img{
    max-width: 400px;
    max-height: 400px;
}

.imageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}