@media only screen and (width>768px){
    .page-indicator{
        position:sticky;
        bottom:0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; 
        /* background-color: red; */
        width: 40%;
        margin-left: 30%;
        }
}

/* @media only screen and (max-width< 1700px){ */
.page-indicator{
    position:sticky;
    bottom:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    /* background-color: red; */
    }
/* } */
    
    .pagenum-container{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        /* position: absolute; */
        height: 3.438rem;
        background: #3F3F3F;
        mix-blend-mode: multiply;
        border-radius: 15px;
    }
    
    .arr{
        padding-top: 14px;
        font-size: 22px;
        color: #939393;
        /* mix-blend-mode: multiply; */
        /* background-color: red; */
        height: auto;
    }
    
    .arr-btn{
        cursor: pointer;
    }
    
    .arr-text{
        padding-top: 9px;
        font-size: small;
        font-weight: 100;
        /* font-family: 'Montserrat'; */
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: #939393;
    }
    
    .num-btn{
        margin-right: -7px;
        margin-top: 11px;
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    /* .num-btn */
    
    .bt-txt,
    .bt-txt:hover{
        border:none;
        outline: none;
        padding: 4px;
        width: -5px;
        font-weight: 500;
        font-weight: 500;
        font-size: 10px;
        color: #939393;
    }
    
    .bt-txt3,
    .bt-txt3:hover{
        border:none;
        outline: none;
        padding: 4px;
        width: -5px;
        font-weight: 500;
        font-size: 13px;
        color: #FFFFFF;
    }

    .bt-txt2,
    .bt-txt2:hover{
        width: 47px;
        height: 30px;
        background: #0094FF;
        border-radius: 15px;
        font-style: normal;
        font-weight: 100;
        font-size: 9px;
        padding-top: 7.5px;
        margin-top: 7px;
        letter-spacing: -0.015em;  
        /* mix-blend-mode: multiply; */
        color: #FFFFFF;
    }

    .limit-text{
        padding-right: 5px;
        padding-top: 10px;
        margin: 4px 4px 0px 0px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 10px;
        outline: none;
        border-radius: 7px;
    }
    
    .pagenum-container input[type=number] {
        margin: 7px 4px 0px 9px;
        padding: 3px;
        font-size: 10px;
        border: 0.2px solid #2EA7FF;
        outline: none;
        border-radius: 5px;
        background: rgba(63,63,63,0.1);
        color: rgba(255, 255, 255, 0.5);
        width: 25px;
        height: 28px;
        -moz-appearance: textfield;
      }
      
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }