.dashboard-count-card{
    height: 13.9375rem;
    border-radius: 1.875rem;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
}

.divider-style{
    height: 9.4375rem;
    width: 0.2rem;
}