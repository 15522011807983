table.log-data tbody tr th {
  padding-right: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
table.log-data {
  font-size: 1rem;
}

.log-data {
  color: #000000;
}

.followup-card-head.serial-number.call {
  width: 4.16666667% !important;
  flex: 0 0 auto;
}

.followup-card-title.call {
  width: 4.16666667% !important;
  flex: 0 0 auto;
}

.btn.btn-success.call {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* cenoy edited */

.MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
  margin: 0;
}

.remove-shadow {
  box-shadow: none;
}

.accordion {
  /* border-top: 5px solid black; */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.accordion.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  border: none;
}

.accordion-details .recent {
  border-bottom: 1px solid gainsboro;
}
