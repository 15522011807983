/* Company List Css Start */

.company-list-card{
    border-radius: 0.9375rem;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 0.06rem 1.44rem 0.94rem 1.44rem;
}

.company-list-header{
    padding: 0.88rem 0rem;
    display: flex;
    align-items: center;
}

.company-list-header .text{
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.03938rem;
    
}

.company-list-header .buttons{
    display: flex;
    align-items: center;
    justify-content: end;
}

.company-add-btn{
    height: 2.8125rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.3125rem;
    padding-left: 1.81rem;
    padding-right: 1.81rem;
    background: #0C0632;
    color: #FFF;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: -0.01313rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company-add-btn:hover{
    background: #19135a;
}

.company-list-table{
    border-radius: 0.625rem;
    padding-left: 1.37rem;
    padding-right: 1.37rem;
    background: #F3F3F3;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.company-list-table .title{
    height: 3.37rem;
    color: #595959;
    font-size: 0.8125rem;
    font-weight: 400;
    letter-spacing: 0.02844rem;
    display: flex;
    align-items: center;
}

.company-list-table .content{
    max-height: 45.1875rem;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: start;

}

.company-list-table .content .item{
    height: 2.1875rem;
    display: flex;
    align-items: center;
    border-radius: 0.3125rem;
    background: #FFF;
    color: #303030;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.02844rem;
}

.company-list-table .content .item .edit-btn{
    height: 1.6625rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.375rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    background: #0C0632;
    color: #FFF;
    font-size: 0.7875rem;
    font-weight: 400;
    letter-spacing: 0.02406rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company-list-table .content .item .edit-btn:hover{
    background: #19135a;
}

.company-list-table .content .item .delete-btn{
    height: 1.6625rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.375rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    background: #b50000;
    color: #FFF;
    font-size: 0.7875rem;
    font-weight: 400;
    letter-spacing: 0.02406rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company-list-table .content .item .delete-btn:hover{
    background: #ef1515;
}

.company-list-table .content .item .subscribe-btn{
    height: 1.6625rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.375rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    background: #e3e302;
    color: #000000;
    font-size: 0.7875rem;
    font-weight: 400;
    letter-spacing: 0.02406rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.company-list-table .content .item .subscribe-btn:hover{
    background: #cdd10d;
}

.active-btn, .deactive-btn{
    height: 1.6625rem;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0.375rem;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    color: white;
    font-size: 0.7875rem;
    font-weight: 400;
    letter-spacing: 0.02406rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active-btn{
    background: green;
}
.deactive-btn{
    font-size: 10px;
    background: red;
}

.active-btn:hover{
    background: #b4aaaa;
}
/* Company List Css End -------------------------------------- */

/* Company form Css start ------------------------------------ */

.Add-company{
    font-size: 1.5rem;
    font-weight: 600;
}

.company-form-body{
    background: #F3F3F3;
    border-radius: 0.4rem;
    margin-right: 2rem;
    padding: 1rem 3rem 3rem 3rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2em;
}

.company-row{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
}

.company-form-input-cont{
    display: flex;
    flex-direction: column;

}

.company-form-input{
    width: 19rem;
    background: #ffff;
    padding: 0.7rem;
    border-radius: 0.3rem;
    outline: none;
    border: none;
    margin-top: 0.3rem;
}

.company-form-input.mobile{
    -moz-appearance: textfield;
    appearance: textfield;
}

.company-form-input.pointer{
    cursor: pointer !important;
}

.submit_btn:hover{
    background-color: #3E64C7 ;
}

.submit_btn{
    outline: none;
    color: #ffff;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    background: #3E64C7;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    border: 0;
    cursor: pointer;
}
.cancel_btn{
    cursor: pointer;
    color: #ffff;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    background: #0C0632;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

.nav-tabs .nav-item a{
    border-color: var(--bs-nav-tabs-link-hover-border-color);
}