.report-tab-menu-item {
  border-radius: 0;
  background-color: #e5e5e5 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.report-tab-menu.ui.tabular.menu .active.item {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: black !important;
  color: #ffffff;
}

.report-tab-menu-item.custom-side {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.report-tab-menu.ui.tabular.menu .active.item.report-tab-menu-item.custom-side {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.report-tab-menu-item.custom-side2 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.report-tab-menu.ui.tabular.menu
  .active.item.report-tab-menu-item.custom-side2 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.ui.attached.tabular.menu {
  min-height: 0px;
}
.report-pane-staff {
  border-collapse: none;
}

/* lead page css */

.MuiStack-root.css-i7y1zb-MuiStack-root {
  padding: 0px;
  margin: 0px;
}

.MuiStack-root.css-13bvbr1-MuiStack-root {
  padding: 0px;
  margin: 0px;
}

.MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #f8f8f8;
  border: none;
  outline: none;
  height: 0px;
  width: 210px;
}

.arrow-btn {
  background-color: #3b3367;
  height: 32px;
  width: 30px;
}

.arrowL {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.arrowR {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

#barchart-test {
  min-height: 100% !important;
}

.customer-box {
  width: 220px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #595959;
}

.customer-box .image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #8b8b8b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calllog-box {
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #13253e;
}

.callbox-heads {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  border-radius: 7px;
}

.callbox-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  height: 150px;
  background-color: #c8cbcf;
  overflow: scroll;
}

.callbox-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 120px;
  border-radius: 7px;
  width: inherit;
}

.callbox-content .content-divs {
  border-radius: 6px;
  width: inherit;
}

.slead-left {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
  color: #414552;
  background-color: white;
}

.slead-left .lead-box {
  background-color: #414552;
  border-radius: 6px;
  height: 70px;
}

.search-input {
  width: 250px;
  outline: none;
  height: 30px;
  border-radius: 15px;
  border-color: gray;
  padding: 5px;
  /* background-image: url("../../assets/images/imgs/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 50px; */
}

.table-body {
  height: 200px;
  overflow: hidden;
}

/* .notfound {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  margin: 0 auto;
} */

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.position-relative {
  position: relative;
}

/* .chart canvas {
  height: 400px !important;
  width: 700px !important;
  margin-left: 120px !important;
} */

.barchart{
  width: 750px;
  /* height: 400px; */
  margin-left: 170px;
}